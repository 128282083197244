export enum EnumComidas {
  Desayuno = 1,
  Tentempie1 = 2,
  Tentempie2 = 3,
  Comida = 4,
  Merienda1 = 5,
  Merienda2 = 6,
  Cena = 7,
  Recena1 = 8,
  Recena2 = 9,
}

import { BaseDto } from '@/shared/dtos/base-dto';

export class PorcentajesGlobalesDieta extends BaseDto {

    public porcen_proteinas: number = 25;
    public gramos_proteinas: number = 0;
    public calorias_proteinas: number = 0;

    public porcen_grasa: number = 25;
    public gramos_grasa: number = 0;
    public calorias_grasa: number = 0;

    public porcen_glucidos: number = 50;
    public gramos_glucidos: number = 0;
    public calorias_glucidos: number = 0;

    public kcal_dieta: number = 1000;
    public peso_paciente: number = 70;

} 















import { Component, Vue } from "vue-property-decorator";
import Horarios_y_comidas from "./Horarios_y_comidas.vue";
import TabsTableSelect from "./TabsTableSelect.vue";
import CalculoMacrosDieta from "./CalculoMacrosDieta.vue";
import FiltrosPlatosDifDeLosPlatos from "./EntrevistaFiltrosPlatos/FiltrosPlatosDifDeLosPlatos.vue";
import FiltrosPlatosTiposPlatos from "./EntrevistaFiltrosPlatos/FiltrosPlatosTiposPlatos.vue";
import FiltrosPlatosTiempo from "./EntrevistaFiltrosPlatos/FiltrosPlatosTiempo.vue";
import Cabezeras_platosList from "./platos/All_platos.vue";
@Component({
  components: {
    Horarios_y_comidas,
    TabsTableSelect,
    CalculoMacrosDieta,
    FiltrosPlatosDifDeLosPlatos,
    FiltrosPlatosTiposPlatos,
    FiltrosPlatosTiempo,
    Cabezeras_platosList,
    
  },
})
export default class TestCard extends Vue {
  created() {}
}





































































































































































import { Component, Vue } from "vue-property-decorator";
import { UtilsNumber } from "@/utils/utils-number";
import modelo_horarioModule from "@/store/modules/modelo_horario-module";
import { horario_comidasViewDto } from "@/shared/dtos/horarios/horario_comidasViewDto";
import { TablaPorcentage } from "@/shared/dtos/horarios/TablaPorcentage";
import { PorcentajesGlobalesDieta } from "@/shared/dtos/horarios/PorcentajesGlobalesDieta";
import { UtilsString } from "@/utils/utils-string";
import { CalculoPorcentajesDieta } from "@/shared/dtos/horarios/CalcularPorcentajesDieta";

@Component({
  components: {
    DashCalculoMacrosDieta: () =>
      import("@/views/test/DashCalculoMacrosDieta.vue")
  }
})
export default class CalculoMacrosDieta extends Vue {
  public color: string = "gray";
  public porcentajes_comidas: TablaPorcentage = new TablaPorcentage();

  public created() {
    //Datos por defecto
    this.changePorcen();
    //comidas seleccionadas
  }

  public get PorcentajesGlobales() {
    return modelo_horarioModule.PorcentajesGlobales;
  }

  public get total_porcentajes_globales() {
    let suma =
      this.dato_pase(this.PorcentajesGlobales.porcen_proteinas) +
      this.dato_pase(this.PorcentajesGlobales.porcen_glucidos) +
      this.dato_pase(this.PorcentajesGlobales.porcen_grasa);
    if (suma.toString() === "NaN") {
      this.color = "red";
      return "Rellene los porcentajes";
    }
    if (suma > 100) {
      this.color = "red";
      return "Total " + suma + "% no puede poner mas de un 100%";
    }
    this.color = "gray";
    return suma;
  }

  public get comidas_seleccionadas() {
    this.changeAll();
    return modelo_horarioModule.comidas_y_horarios;
  }
  public changeAll() {
    this.porcentajes_comidas.GetKcalPorComida(this.PorcentajesGlobales);
  }
  public changePorcen() {
    this.calculo_gramos();
    this.calculokcal();
  }

  public changeGramos() {
    this.calculokcal();
    this.calculoporcentajes();
    this.porcentajes_comidas.GetKcalPorComida(this.PorcentajesGlobales);
  }

  public changeKcal() {
    this.calculoporcentajes();
    this.calculo_gramos();
    this.porcentajes_comidas.GetKcalPorComida(this.PorcentajesGlobales);
  }

  public calculo_gramos() {
    this.PorcentajesGlobales.gramos_proteinas = this.de_porcetaje_agramos(
      this.PorcentajesGlobales.porcen_proteinas,
      4
    );
    this.PorcentajesGlobales.gramos_glucidos = this.de_porcetaje_agramos(
      this.PorcentajesGlobales.porcen_glucidos,
      4
    );
    this.PorcentajesGlobales.gramos_grasa = this.de_porcetaje_agramos(
      this.PorcentajesGlobales.porcen_grasa,
      9
    );
  }

  public calculokcal() {
    this.PorcentajesGlobales.calorias_proteinas = this.de_gramos_a_kcal(
      this.PorcentajesGlobales.gramos_proteinas,
      4
    );
    this.PorcentajesGlobales.calorias_glucidos = this.de_gramos_a_kcal(
      this.PorcentajesGlobales.gramos_glucidos,
      4
    );
    this.PorcentajesGlobales.calorias_grasa = this.de_gramos_a_kcal(
      this.PorcentajesGlobales.gramos_grasa,
      9
    );
  }

  public calculoporcentajes() {
    this.PorcentajesGlobales.porcen_proteinas = this.de_kcal_a_porcen(
      this.PorcentajesGlobales.calorias_proteinas
    );
    this.PorcentajesGlobales.porcen_glucidos = this.de_kcal_a_porcen(
      this.PorcentajesGlobales.calorias_glucidos
    );
    this.PorcentajesGlobales.porcen_grasa = this.de_kcal_a_porcen(
      this.PorcentajesGlobales.calorias_grasa
    );
  }

  public de_porcetaje_agramos(dato: any, valor_nutricional: number): number {
    //prettier-ignore
    return  UtilsNumber.RoudTwoDecimals((((Number.parseFloat(dato.toString()) * this.PorcentajesGlobales.kcal_dieta) / 100) / valor_nutricional) / this.PorcentajesGlobales.peso_paciente);
  }

  public de_gramos_a_kcal(dato: any, valor_nutricional: number): number {
    //prettier-ignore
    return  Math.round(((Number.parseFloat(dato.toString()) * valor_nutricional) * this.PorcentajesGlobales.peso_paciente));
  }
  public dato_pase(dato: any): number {
    if (UtilsString.IsNullOrWhiteSpace(dato)) {
      return 0;
    }
    //prettier-ignore
    return  Number.parseFloat(dato.toString());
  }

  public de_kcal_a_porcen(dato: any): number {
    //prettier-ignore
    return  UtilsNumber.RoudTwoDecimals(((Number.parseFloat(dato.toString()) * 100) / this.PorcentajesGlobales.kcal_dieta));
  }
}

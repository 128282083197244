
























import { Component, Vue } from "vue-property-decorator";
import cabezeras_platosModule from "./cabezeras_platos-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { cabezeras_platos } from "./cabezeras_platos";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class Cabezeras_platosList extends Vue {
  created() {
    cabezeras_platosModule.getcabezeras_platos();
  }

  get source() {
    return cabezeras_platosModule.cabezeras_platos;
  }

  get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "id",
        "Id",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );

    return Columnas;
  }
}

import { estructura_basicaDto } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/estructura_basicaDto';

export class alimentos_fichaDto extends estructura_basicaDto {
    public sistema !: boolean;
    public nombre !: string;
    public porcion_comestible !: string;
    public azucares_anadidos !: string;
    
    public id_grupo_alimento !: number;
    public id_temporada !: number;
}
















import { Component, Vue } from "vue-property-decorator";
import dificultad_platoModule from "@/store/modules/dificultad_plato-module";
import { dificultad_plato } from "@/shared/dtos/dificultad_plato";

@Component({
  components: {}
})
export default class FiltrosPlatosDifDeLosPlatos extends Vue {
  public created() {
    dificultad_platoModule.getdificultad_platos();
  }
  public get dificultades() {
    return dificultad_platoModule.dificultad_platos.filter(x => x.nivel > 0);
  }
}
































import { Component, Vue } from "vue-property-decorator";
import TableSelect from "@/components/DataTableSelect/TableSelect.vue";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
@Component({
  components: { TableSelect }
})
export default class TabsTableSelect extends Vue {
  public tab: any = null;

  public created() {
    nueva_platoModule.getall_platos_gustos_paciente(2);
    alimentoModule.getall_alimentos_gustos_paciente(2);
  }

  public get platos() {
    return nueva_platoModule.All_nueva_plato_gustos;
  }
  public get no_gustan_platos() {
    return nueva_platoModule.NoGustan_nueva_plato_gustos;
  }

  public get alimentos() {
    return alimentoModule.All_nueva_alimento_gustos;
  }
  public get no_gustan_alimentos() {
    return alimentoModule.NoGustan_nueva_alimento_gustos;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        value: "nombre",
        width: "50%"
      },
      { text: "Grupo", value: "grupo", width: "50%" }
    ];
  }

  public get tolheight() {
    return this.$vuetify.breakpoint.height-307;
  }

  public acc(){
    
  }
}

import { store } from '@/store/store';
import { nueva_plato } from '@/shared/dtos/visualizacion_dieta/nueva_plato';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { plato_ficha } from '@/shared/dtos/visualizacion_dieta/plato_ficha';
import { FilterDataDto } from '@/shared/dtos/visualizacion_dieta/FilterDataDto';
import { gustos_platosDto } from '@/shared/dtos/visualizacion_dieta/entrevista/gustos_platosDto';
import { id_texto_seleccionadoDto } from '@/shared/dtos/generalesDto/id_texto_seleccionadoDto';
import { tipo_platoDto } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/tipo_platoDto';
import { talla_platoDto } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/talla_platoDto';

@Module({
    namespaced: true,
    name: 'nueva_platoModule',
    store,
    dynamic: true
})
class nueva_platoModule extends VuexModule {
    public nueva_platos: nueva_plato[] = [];
    public nueva_plato: nueva_plato = new nueva_plato();
    public nueva_plato_ficha: plato_ficha[] = [];
    public All_nueva_plato_gustos: gustos_platosDto[] = [];
    public NoGustan_nueva_plato_gustos: gustos_platosDto[] = [];
    public duraciones: id_texto_seleccionadoDto[] = [];

    public tipos_platos_asignar_comida: id_texto_seleccionadoDto[] = [];
    public tipos_platos_asignar_cena: id_texto_seleccionadoDto[] = [];

    public tipos_postres_asignar_comida: id_texto_seleccionadoDto[] = [];
    public tipos_postres_asignar_cena: id_texto_seleccionadoDto[] = [];

    public postre_seleccionado_comida: id_texto_seleccionadoDto = new id_texto_seleccionadoDto();
    public postre_seleccionado_cena: id_texto_seleccionadoDto = new id_texto_seleccionadoDto();
    public tiempo_maximo: id_texto_seleccionadoDto = new id_texto_seleccionadoDto();

    public tipos_plato: tipo_platoDto[] = [];
    public tallas_plato: talla_platoDto[] = [];

    public all_nueva_platos: FilterDataDto[] = [];

    @Action({ commit: 'onGetnueva_platos' })
    public async getnueva_platos() {
        return await ssmHttpService.get(API.nueva_plato);
    }

    @Action({ commit: 'onGetnueva_plato_ficha' })
    public async getnueva_plato_ficha(id: number) {
        return await ssmHttpService.get(API.nueva_plato + '/ficha/' + id);
    }

    @Action({ commit: 'onGetall_platos' })
    public async getall_platos({ id_dieta, id_paciente }: any) {
        return await ssmHttpService.get(API.nueva_plato + '/all/' + id_dieta + '/' + id_paciente);
    }

    @Action({ commit: 'onGetall_platos' })
    public async getlista_platos() {
        return await ssmHttpService.get(API.nueva_plato + '/lista_platos/');
    }
    

    @Action({ commit: 'onGetDuraciones' })
    public async getduraciones() {
        return await ssmHttpService.get(API.nueva_plato + '/duraciones/');
    }

    @Action({ commit: 'onGetAsignar_platos' })
    public async getasignar_platos() {
        return await ssmHttpService.get(API.nueva_plato + '/asignar_platos/');
    }

    @Action({ commit: 'onGetAsignar_postres' })
    public async getasignar_postres() {
        return await ssmHttpService.get(API.nueva_plato + '/asignar_postres/');
    }

    @Action({ commit: 'onGetTipos_plato' })
    public async getTipos_plato() {
        return await ssmHttpService.get(API.nueva_plato + '/tipos_plato/');
    }

    @Action({ commit: 'onGetTallas_plato' })
    public async getTallas() {
        return await ssmHttpService.get(API.nueva_plato + '/tallas/');
    }

    @Action({ commit: 'onGetall_platos_gustos' })
    public async getall_platos_gustos_paciente(id_paciente: number) {
        return await ssmHttpService.get(API.nueva_plato + '/gustos_paciente/' + id_paciente);
    }

    @Action({ commit: 'onGetnueva_plato' })
    public async getnueva_plato(id: any) {
        return await ssmHttpService.get(API.nueva_plato + '/' + id);
    }

    @Action
    public async guardarnueva_plato(nueva_plato: nueva_plato) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.nueva_plato, nueva_plato.toJson());
        this.getnueva_platos();
    }


    @Action
    public async guardar_ficha_nueva_plato(nueva_plato: plato_ficha[]) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.nueva_plato + '/ficha', JSON.stringify(nueva_plato));
    }

    @Action
    public async modificar_ficha_nueva_plato(nueva_plato: plato_ficha[]) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.put(API.nueva_plato + '/ficha', JSON.stringify(nueva_plato));
    }

    @Action
    public async modificarnueva_plato(nueva_plato: nueva_plato) {
        await ssmHttpService.put(API.nueva_plato, nueva_plato);
        this.getnueva_platos();
    }

    @Action
    public async search_plato({ id_dieta, id_paciente }: any) {
        if (this.all_nueva_platos.length === 0) {
            await this.getall_platos({ id_dieta: id_dieta, id_paciente: id_paciente });
        }
        return this.all_nueva_platos;
    }


    @Action
    public async eliminarnueva_plato(nueva_plato: nueva_plato) {
        await ssmHttpService.delete(API.nueva_plato + '/' + nueva_plato.id, null, false);
        this.getnueva_platos();
    }

    @Mutation
    public onGetnueva_platos(res: nueva_plato[]) {
        this.nueva_platos = res ? res.map((x) => new nueva_plato(x)) : []
    }

    @Mutation
    public onGetnueva_plato(res: nueva_plato) {
        this.nueva_plato = new nueva_plato(res);
    }

    @Mutation
    public onGetTipos_plato(res: tipo_platoDto[]) {
        this.tipos_plato = res;
    }
    
    @Mutation
    public onGetTallas_plato(res: talla_platoDto[]) {
        this.tallas_plato = res;
    }


    @Mutation
    public onGetnueva_plato_ficha(res: plato_ficha[]) {
        this.nueva_plato_ficha = res;// ? res.map((x) => new plato_ficha(x)) : []
    }

    @Mutation
    public onGetall_platos(res: FilterDataDto[]) {
        this.all_nueva_platos = res ? res.map((x) => new FilterDataDto(x)) : []
    }

    @Mutation
    public onGetall_platos_gustos(res: gustos_platosDto[]) {
        this.All_nueva_plato_gustos = res ? res.map((x) => new gustos_platosDto(x)) : []
        this.NoGustan_nueva_plato_gustos = this.All_nueva_plato_gustos.filter((x: gustos_platosDto) => x.seleccionado === true);
    }

    @Mutation
    public onGetall_platos_search(res: FilterDataDto[]) {
        this.all_nueva_platos = res ? res.map((x) => new FilterDataDto(x)) : [];
    }

    @Mutation
    public onGetDuraciones(res: id_texto_seleccionadoDto[]) {
        this.duraciones = res ? res.map((x) => new id_texto_seleccionadoDto(x)) : [];
    }

    @Mutation
    public onGetAsignar_platos(res: id_texto_seleccionadoDto[]) {
        this.tipos_platos_asignar_comida = res ? res.map((x) => new id_texto_seleccionadoDto(x)) : [];
        this.tipos_platos_asignar_cena = res ? res.map((x) => new id_texto_seleccionadoDto(x)) : [];
    }

    @Mutation
    public onGetAsignar_postres(res: id_texto_seleccionadoDto[]) {
        this.tipos_postres_asignar_cena = res ? res.map((x) => new id_texto_seleccionadoDto(x)) : [];
        this.tipos_postres_asignar_comida = res ? res.map((x) => new id_texto_seleccionadoDto(x)) : [];
    }

    @Mutation
    public SetPostreCena(res: id_texto_seleccionadoDto) {
        this.postre_seleccionado_cena = res;
    }

    @Mutation
    public SetPostreComida(res: id_texto_seleccionadoDto) {
        this.postre_seleccionado_comida = res;
    }

    @Mutation
    public SetTiempoMaximo(res: id_texto_seleccionadoDto) {
        this.tiempo_maximo = res;
    }
    @Mutation
    public Set_Platos_gustos(res: gustos_platosDto[]) {
        this.NoGustan_nueva_plato_gustos = res;
    }


}
export default getModule(nueva_platoModule);
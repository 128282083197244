import { BaseDto } from '@/shared/dtos/base-dto';

export class alimento_ficha extends BaseDto {
    public nombre !: string;

    public porcion_comestible !: string;
    public azucares_anadidos !: string;

    public nombre_grupo_ali !: string;
    public observaciones !: string;
    public nombre_temporada !: string;

    public energia !: string;
    public etanol !: string;
    
    public glucidos_totales !: string;
    public polisacaridos !: string;
    public azucares !: string;
    public fibra !: string;

    public grasas_totales !: string;
    public agmi !: string;
    public agpi !: string;
    public ags !: string;
    public colesterol !: string;

    public proteinas_totales !: string;
    public proteinas_animales !: string;
    public proteinas_vegetales !: string;

    public iodo !: string;
    public sodio !: string;
    public potasio !: string;
    public calcio !: string;
    public magnesio !: string;
    public fosforo !: string;
    public hierro !: string;
    public zinc !: string;
    public selenio !: string;

    public vitamina_b1 !: string;
    public vitamina_b2 !: string;
    public vitamina_b6 !: string;
    public vitamina_b12 !: string;
    public acido_folico !: string;
    public niacina !: string;
    public vitamina_c !: string;
    public vitamina_a !: string;
    public vitamina_d !: string;
    public vitamina_e !: string;

    public sistema !: boolean;
    public id_estructura !: number;
    public id_grupo_alimento !: number;
    public id_temporada !: number;
} 

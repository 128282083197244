import { store } from '@/store/store';
import { modelo_horario } from "@/shared/dtos/horarios/modelo_horario";
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { horario_comidasViewDto } from '@/shared/dtos/horarios/horario_comidasViewDto';
import { comidas_horarios } from '@/shared/dtos/horarios/comidas_horarios';
import { CalculoPorcentajesDieta } from '@/shared/dtos/horarios/CalcularPorcentajesDieta';
import { PorcentajesGlobalesDieta } from '@/shared/dtos/horarios/PorcentajesGlobalesDieta';

@Module({
    namespaced: true,
    name: 'modelo_horarioModule',
    store,
    dynamic: true
})
class modelo_horarioModule extends VuexModule {
    public modelo_horarios: modelo_horario[] = [];
    public modelo_horario: modelo_horario = new modelo_horario();
    public modelo_horarios_view: horario_comidasViewDto[] = [];
    public comidas_y_horarios: comidas_horarios[] = [];
    public PorcentajesGlobales: PorcentajesGlobalesDieta = new PorcentajesGlobalesDieta();


    @Action({ commit: 'onGetmodelo_horarios' })
    public async getmodelo_horarios() {
        return await ssmHttpService.get(API.modelo_horarios);
    }

    @Action({ commit: 'onGetmodelo_horario' })
    public async getmodelo_horario(id: any) {
        return await ssmHttpService.get(API.modelo_horarios + '/' + id);
    }

    @Action({ commit: 'onGetDataModelo_horario' })
    public async getDataModelo_horario(id_modelo: number) {
        return await ssmHttpService.get(API.modelo_horarios + '/data_modelo/' + id_modelo);
    }

    @Action
    public async guardarmodelo_horario(modelo_horario: modelo_horario) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.modelo_horarios, modelo_horario.toJson());
        this.getmodelo_horarios();
    }

    @Action
    public async modificarmodelo_horario(modelo_horario: modelo_horario) {
        await ssmHttpService.put(API.modelo_horarios, modelo_horario);
        this.getmodelo_horarios();
    }

    @Action
    public async eliminarmodelo_horario(modelo_horario: modelo_horario) {
        await ssmHttpService.delete(API.modelo_horarios + '/' + modelo_horario.id, null, false);
        this.getmodelo_horarios();
    }

    @Mutation
    public onGetmodelo_horarios(res: modelo_horario[]) {
        this.modelo_horarios = res ? res.map((x) => new modelo_horario(x)) : [];
    }

    @Mutation
    public onGetDataModelo_horario(res: horario_comidasViewDto[]) {
        this.modelo_horarios_view = res ? res.map((x) => new horario_comidasViewDto(x)) : [];
    }

    @Mutation
    public SetOperativeHorarios_y_Comidas(res: horario_comidasViewDto[]) {
        this.comidas_y_horarios = [];
        for (let i = 0; i < res.length; i++) {
            if (res[i].hora !== '00:00') {
                let model: comidas_horarios = new comidas_horarios();
                model.nombre_comida = res[i].nombre_comida;
                model.id_comida = res[i].id_comida;
                model.porcen = 0;
                model.calorias = 0;
                this.comidas_y_horarios.push(model);
            }
        }
        let ids_com = res
            ? res.map(x => x.id_comida)
            : [];
        new CalculoPorcentajesDieta(ids_com).GetPorcentajes();
    }

    @Mutation
    public onGetmodelo_horario(res: modelo_horario) {
        this.modelo_horario = new modelo_horario(res);
    }

}
export default getModule(modelo_horarioModule);
import { BaseDto } from '@/shared/dtos/base-dto';

export class alimento extends BaseDto {
    public id_estructura !: number;
    public nombre !: string;
    public observaciones !: string;
    public porcion_comestible !: string;
    public azucares_anadidos !: string;
    public path !: string;
    public updated!: Date;
    public id_grupo_alimento !: number;
    public id_temporada !: number;
    public thumbnail !: string;
    public sistema !: boolean;
    public id_nutricionista !: number;

} 

import { store } from '@/store/store';
import { alimento } from '@/shared/dtos/visualizacion_dieta/alimento';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { alimento_ficha } from '@/shared/dtos/visualizacion_dieta/alimento_ficha';
import { FilterDataDto } from '@/shared/dtos/visualizacion_dieta/FilterDataDto';
import { gustos_platosDto } from '@/shared/dtos/visualizacion_dieta/entrevista/gustos_platosDto';
import { alimentos_fichaDto } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/alimentos_fichaDto';
import { temporada } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/temporada';
import { grupo_alimentos } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/grupo_alimentos';

@Module({
    namespaced: true,
    name: 'alimentoModule',
    store,
    dynamic: true
})
class alimentoModule extends VuexModule {
    public alimentos: alimento[] = [];
    public alimento: alimento = new alimento();
    public alimento_ficha: alimento_ficha = new alimento_ficha();

    public All_nueva_alimento_gustos: gustos_platosDto[] = [];
    public NoGustan_nueva_alimento_gustos: gustos_platosDto[] = [];

    public alimentos_ficha_alta: alimentos_fichaDto = new alimentos_fichaDto();

    public temporadas: temporada[] = [];
    public grupos_alimentos: grupo_alimentos[] = [];

    public alimentos_fichas: alimento_ficha[] = [];



    public all_alimentos: FilterDataDto[] = [];

    public local_db_alimentos_fichas: alimento_ficha[] = [];


    @Action({ commit: 'onGetalimentos' })
    public async getalimentos() {
        return await ssmHttpService.get(API.alimento);
    }

    @Action({ commit: 'onGettemporadas' })
    public async gettemporadas() {
        return await ssmHttpService.get(API.alimento + '/temporada/');
    }

    @Action({ commit: 'onGetgrupos_alimentos' })
    public async getgrupos_alimentos() {
        return await ssmHttpService.get(API.alimento + '/grupo_alimentos/');
    }

    @Action({ commit: 'onGetalimento' })
    public async getalimento(id: any) {
        return await ssmHttpService.get(API.alimento + '/' + id);
    }

    @Action({ commit: 'onGetall_alimentos' })
    public async getall_alimentos({ id_dieta, id_paciente }: any) {
        return await ssmHttpService.get(API.alimento + '/all/' + id_dieta + '/' + id_paciente);
    }

    @Action({ commit: 'onGetall_alimentos' })
    public async getall_alimentos_lista() {
        return await ssmHttpService.get(API.alimento + '/lista/');
    }

    @Action({ commit: 'onGetalimentoFicha' })
    public async getalimentoficha(id_estructura: number) {
        return await ssmHttpService.get(API.alimento + '/ficha/' + id_estructura);
    }

    @Action({ commit: 'onGetall_alimentos_gustos' })
    public async getall_alimentos_gustos_paciente(id_paciente: number) {
        return await ssmHttpService.get(API.alimento + '/gustos_paciente/' + id_paciente);
    }


    @Action
    public async guardaralimento(alimento: alimento) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.alimento, alimento.toJson());
    }


    @Action
    public async guardar_ficha_alimento(alimento: alimento_ficha) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.alimento + '/ficha', alimento.toJson());
    }

    @Action
    public async modificar_ficha_alimento(alimento: alimento_ficha) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.put(API.alimento + '/ficha', alimento);
    }

    @Action
    public async eliminar_ficha_alimento(id_alimento: number) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.delete(API.alimento + '/ficha/' + id_alimento, null, false);
    }


    @Action({ commit: 'onGetFichasAlimentos' })
    public async getalimentofichas(id_estructura: number[]) {
        return await ssmHttpService.post(API.alimento + '/fichas/', JSON.stringify(id_estructura));
    }

    @Action
    public async search_alimento({ id_dieta, id_paciente }: any) {
        if (this.all_alimentos.length === 0) {
            await this.getall_alimentos({ id_dieta: id_dieta, id_paciente: id_paciente });
        }
        return this.all_alimentos;
    }


    @Action
    public async modificaralimento(alimento: alimento) {
        await ssmHttpService.put(API.alimento, alimento);
    }

    @Action
    public async eliminaralimento(alimento: alimento) {
        await ssmHttpService.delete(API.alimento + '/' + alimento.id, null, false);
    }

    @Mutation
    public onGetalimentos(res: alimento[]) {

        this.alimentos = res ? res.map((x) => new alimento(x)) : []
    }

    @Mutation
    public onGettemporadas(res: temporada[]) {
        this.temporadas = res ? res.map((x) => new temporada(x)) : []
    }

    @Mutation
    public onGetgrupos_alimentos(res: grupo_alimentos[]) {
        this.grupos_alimentos = res ? res.map((x) => new grupo_alimentos(x)) : []
    }


    @Mutation
    public onGetalimento(res: alimento) {
        this.alimento = new alimento(res);
    }

    @Mutation
    public onGetalimentoFicha(res: alimento_ficha) {
        this.alimento_ficha = new alimento_ficha(res);
        this.alimentos_ficha_alta = new alimentos_fichaDto(res);
    }

    @Mutation
    public onGetFichasAlimentos(res: alimento_ficha[]) {
        this.alimentos_fichas = res ? res.map((x) => new alimento_ficha(x)) : [];
        for (let i = 0; i < this.alimentos_fichas.length; i++) {
            this.local_db_alimentos_fichas.push(this.alimentos_fichas[i]);
        }

    }

    @Mutation
    public onGetalimentoFichaReset(res: alimentos_fichaDto) {
        this.alimentos_ficha_alta = res;
    }

    @Mutation
    public onGetall_alimentos(res: FilterDataDto[]) {
        this.all_alimentos = res ? res.map((x) => new FilterDataDto(x)) : []
    }

    @Mutation
    public onGetall_alimentos_gustos(res: gustos_platosDto[]) {
        this.All_nueva_alimento_gustos = res ? res.map((x) => new gustos_platosDto(x)) : []
        this.NoGustan_nueva_alimento_gustos = res.filter((x: gustos_platosDto) => x.seleccionado === true);
    }

    @Mutation
    public Set_alimentos_gustos(res: gustos_platosDto[]) {
        this.NoGustan_nueva_alimento_gustos = res;
    }

    @Mutation
    public onGetall_alimentos_search(res: FilterDataDto[]) {
        this.all_alimentos = res ? res.map((x) => new FilterDataDto(x)) : []
    }


}
export default getModule(alimentoModule);
import { TablaPorcentage } from "./TablaPorcentage";
import { EnumComidas } from "./EnumComidas";

export class CalculoPorcentajesDieta {
  public _comidas!: number[];
  constructor(comidas: number[]) {
    this._comidas = comidas;
  }
  public GetPorcentajes(): TablaPorcentage {
    let tabla: TablaPorcentage = new TablaPorcentage();
    tabla.SetToDefaultData();
    if (!this.Hay(EnumComidas.Tentempie1, this._comidas)) {
      tabla.SetPorcenToComida(EnumComidas.Tentempie1, 0);
    }
    if (!this.Hay(EnumComidas.Tentempie2, this._comidas)) {
      tabla.SetPorcenToComida(EnumComidas.Tentempie2, 0);
    }

    if (!this.Hay(EnumComidas.Merienda1, this._comidas)) {
      tabla.SetPorcenToComida(EnumComidas.Merienda1, 0);
    }
    if (!this.Hay(EnumComidas.Merienda2, this._comidas)) {
      tabla.SetPorcenToComida(EnumComidas.Merienda2, 0);
    }

    if (!this.Hay(EnumComidas.Recena1, this._comidas)) {
      tabla.SetPorcenToComida(EnumComidas.Recena1, 0);
    }
    if (!this.Hay(EnumComidas.Recena2, this._comidas)) {
      tabla.SetPorcenToComida(EnumComidas.Recena2, 0);
    }

    let sumatorio = tabla.SumaPorcentajes;
    if (sumatorio < 100) {
      let falta_para_100 = 100 - sumatorio;
      if (falta_para_100 <= 10) {
        let Comida = tabla.GetPorcenToComida(EnumComidas.Comida) + Math.round(falta_para_100);
        tabla.SetPorcenToComida(EnumComidas.Comida, Comida);
      } else {
        tabla.SetPorcenToComida(EnumComidas.Comida, 40);
        if (tabla.SumaGrupoD() < 25) {
          let diffD = 25 - tabla.SumaGrupoD();
          tabla.SetPorcenToComida(EnumComidas.Desayuno, Math.round(tabla.GetPorcenToComida(EnumComidas.Desayuno) + diffD));
        }
        if (sumatorio < 100) {
          if (tabla.GetPorcenToComida(EnumComidas.Recena1) !== 0) {
            tabla.SetPorcenToComida(EnumComidas.Recena1, tabla.GetPorcenToComida(EnumComidas.Recena1) + tabla.MaxAsignarGrupoCe());
          }
          if (tabla.GetPorcenToComida(EnumComidas.Recena2) !== 0) {
            tabla.SetPorcenToComida(EnumComidas.Recena2, tabla.GetPorcenToComida(EnumComidas.Recena2) + tabla.MaxAsignarGrupoCe());
          }
        }
        if (sumatorio < 100) {
          tabla.SetPorcenToComida(EnumComidas.Cena, tabla.GetPorcenToComida(EnumComidas.Cena) + tabla.MaxAsignarGrupoCe());

        }
      }
    }

    return tabla;
  }

  public Hay(comida: EnumComidas, comidas: number[]) {
    return comidas.indexOf(comida) >= 0;
  }
}

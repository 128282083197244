import { BaseDto } from '@/shared/dtos/base-dto';

export class horario_comidasViewDto extends BaseDto {
    public nombre_horario !: string;
    public nombre_comida !: string;
    public id_comida !: number;
    public hora !: string;
    public dialog_show: boolean = false;
    public valido: boolean = false;
    public error: string = '';
} 

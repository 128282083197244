






















































import { Component, Vue, Watch } from "vue-property-decorator";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";

@Component({
  components: {}
})
export default class FiltrosPlatosTiposPlatos extends Vue {
  public postre_asignar_cena: number = 0;
  public postre_asignar_comida: number = 0;
  public suplementos: boolean = false;

  public async created() {
    nueva_platoModule.getasignar_platos();
    await nueva_platoModule.getasignar_postres();
    this.postre_asignar_cena = this.postrescena.filter(
      x => x.seleccionado === true
    )[0].id;
    this.postre_asignar_comida = this.postrescomida.filter(
      x => x.seleccionado === true
    )[0].id;
  }
  public get platos_asignar_comida() {
    return nueva_platoModule.tipos_platos_asignar_comida;
  }

  public get platos_asignar_cena() {
    return nueva_platoModule.tipos_platos_asignar_cena;
  }

  public get postrescena() {
    return nueva_platoModule.tipos_postres_asignar_cena;
  }
  public get postrescomida() {
    return nueva_platoModule.tipos_postres_asignar_comida;
  }

  @Watch("postre_asignar_cena")
  public setPostreCena() {
    nueva_platoModule.SetPostreCena(
      this.postrescena.filter(x => x.id === this.postre_asignar_cena)[0]
    );
  }
  @Watch("postre_asignar_comida")
  public setPostreComida() {
    nueva_platoModule.SetPostreComida(
      this.postrescena.filter(x => x.id === this.postre_asignar_comida)[0]
    );
  }
}

import { EnumComidas } from "./EnumComidas";
import { UtilsString } from '@/utils/utils-string';
import modelo_horarioModule from '@/store/modules/modelo_horario-module';
import { PorcentajesGlobalesDieta } from './PorcentajesGlobalesDieta';

export class TablaPorcentage {

    constructor() {
    }

    public SetToDefaultData() {
        this.SetPorcenToComida(EnumComidas.Desayuno, 15);
        this.SetPorcenToComida(EnumComidas.Tentempie1, 5);
        this.SetPorcenToComida(EnumComidas.Tentempie2, 5);
        this.SetPorcenToComida(EnumComidas.Comida, 30);
        this.SetPorcenToComida(EnumComidas.Merienda1, 8);
        this.SetPorcenToComida(EnumComidas.Merienda2, 7);
        this.SetPorcenToComida(EnumComidas.Cena, 20);
        this.SetPorcenToComida(EnumComidas.Recena1, 5);
        this.SetPorcenToComida(EnumComidas.Recena2, 5);
    }

    public SUMA: number = 100;

    public GetPorcenToComida(id_comida: EnumComidas): number {
        let comida = modelo_horarioModule.comidas_y_horarios.filter((x) => x.id_comida === id_comida);
        if (comida !== undefined && comida.length > 0) {
            return comida[0].porcen;
        }
        return 0;
    }

    public SetPorcenToComida(id_comida: EnumComidas, nuevo_dato: any) {
        nuevo_dato = Number.parseInt(UtilsString.ValueOf(nuevo_dato));
        let comida = modelo_horarioModule.comidas_y_horarios.filter((x) => x.id_comida === id_comida);
        if (comida !== undefined && comida.length > 0) {
            comida[0].porcen = nuevo_dato;
        }
    }

    public SetKcalToComida(id_comida: EnumComidas, nuevo_dato: any) {
        nuevo_dato = Number.parseInt(UtilsString.ValueOf(nuevo_dato));
        let comida = modelo_horarioModule.comidas_y_horarios.filter((x) => x.id_comida === id_comida);
        if (comida !== undefined && comida.length > 0) {
            comida[0].calorias = nuevo_dato;
        }
    }

    public get GetHorariosComidas() {
        return modelo_horarioModule.comidas_y_horarios
    }

    /**
     * SumaPorcentajes
     */
    public get SumaPorcentajes() {
        this.SUMA = (modelo_horarioModule.comidas_y_horarios.map((x) => Number.parseInt(UtilsString.ValueOf(x.porcen))).reduce((a, b) => a + b, 0)
        );
        return this.SUMA;
    }
    public SumaGrupoD() {
        return this.GetPorcenToComida(EnumComidas.Desayuno) + this.GetPorcenToComida(EnumComidas.Tentempie1) + this.GetPorcenToComida(EnumComidas.Tentempie2);
    }

    public SumaGrupoC() {
        return this.GetPorcenToComida(EnumComidas.Comida);
    }

    public SumaGrupoM() {
        return this.GetPorcenToComida(EnumComidas.Merienda1) + this.GetPorcenToComida(EnumComidas.Merienda2);
    }

    public SumaGrupoCe() {
        return this.GetPorcenToComida(EnumComidas.Cena) + this.GetPorcenToComida(EnumComidas.Recena1) + this.GetPorcenToComida(EnumComidas.Recena2);
    }

    public MaxAsignarGrupoM(): number {
        let sumatorio = this.SumaPorcentajes;
        if (sumatorio < 100) {
            let diff = 100 - sumatorio;
            if (diff > 15) {
                return 15;
            } else return Math.round(diff - this.GetPorcenToComida(EnumComidas.Merienda1) - this.GetPorcenToComida(EnumComidas.Merienda2));
        } else {
            return 0;
        }
    }

    public MaxAsignarGrupoCe(): number {
        let sumatorio = this.SumaPorcentajes;
        if (sumatorio < 100) {
            let diff = 100 - sumatorio;
            if (diff > 30) {
                return 30;
            } else return Math.round(diff);
        } else {
            return 0;
        }
    }

    public GetKcalPorComida(datos: PorcentajesGlobalesDieta) {
        for (let i = 0; i < this.GetHorariosComidas.length; i++) {
            try {
                let kcal_diarias_GR = (datos.porcen_grasa * datos.kcal_dieta) / 100
                let kcal_diarias_PR = (datos.porcen_proteinas * datos.kcal_dieta) / 100
                let kcal_diarias_HC = (datos.porcen_glucidos * datos.kcal_dieta) / 100

                let kcal_comida_GR = (this.GetHorariosComidas[i].porcen * kcal_diarias_GR) / 100
                let kcal_comida_PR = (this.GetHorariosComidas[i].porcen * kcal_diarias_PR) / 100
                let kcal_comida_HC = (this.GetHorariosComidas[i].porcen * kcal_diarias_HC) / 100

                let Kcal_comida = kcal_comida_GR + kcal_comida_PR + kcal_comida_HC

                let gramos_comida_GR = (kcal_comida_GR) / 9
                let gramos_comida_PR = (kcal_comida_PR) / 4
                let gramos_comida_HC = (kcal_comida_HC) / 4

                this.SetKcalToComida(this.GetHorariosComidas[i].id_comida, Kcal_comida);

            } catch (error) {

            }
        }
    }
}

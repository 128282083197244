import { store } from '@/store/store';
import { dificultad_plato } from '@/shared/dtos/dificultad_plato';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'dificultad_platoModule',
    store,
    dynamic: true
})
class dificultad_platoModule extends VuexModule {
    public dificultad_platos: dificultad_plato[] = [];
    public dificultad_plato: dificultad_plato = new dificultad_plato();

    @Action({ commit: 'onGetdificultad_platos' })
    public async getdificultad_platos() {
        return await ssmHttpService.get(API.dificultad_plato);
    }

    @Action({ commit: 'onGetdificultad_plato' })
    public async getdificultad_plato(id: any) {
        return await ssmHttpService.get(API.dificultad_plato + '/' + id);
    }

    @Mutation
    public onGetdificultad_platos(res: dificultad_plato[]) {
        this.dificultad_platos = res ? res.map((x) => { let f = new dificultad_plato(x); f.seleccionado = true; return f }) : []
    }

    @Mutation
    public onGetdificultad_plato(res: dificultad_plato) {
        this.dificultad_plato = new dificultad_plato(res);
    }

}
export default getModule(dificultad_platoModule);
import { BaseDto } from '@/shared/dtos/base-dto';

export class cabezeras_platos extends BaseDto {
    public nombre !: string;
    public duracion !: string;
    public comensales !: string;
    public receta !: string;
    public observaciones !: string;
    public apropiado_desayuno !: boolean;
    public apropiado_tentempie !: boolean;
    public apropiado_comida !: boolean;
    public apropiado_merienda !: boolean;
    public apropiado_cena !: boolean;
    public apropiado_recena !: boolean;
    public apropiado_postre !: boolean;
    public solo_deportist !: boolean;
    public solo_veganos !: boolean;
    public apto_veganos !: boolean;
    public solo_celiacos !: boolean;
    public solo_paleo !: boolean;
    public apto_paleo !: boolean;
    public solo_intolerantes_lactosa !: boolean;
    public solo_textura !: boolean;
    public apto_textura !: boolean;
    public apto_gtritis !: boolean;
    public apto_menopausia !: boolean;
    public apto_hipertension !: boolean;
    public apto_obesidad !: boolean;
    public apto_hipertrigliceridemia !: boolean;
    public apto_hipertrigliceridemia_grave !: boolean;
    public plato_unico !: boolean;
    public alergeno_crustaceos !: boolean;
    public alergeno_lacteos !: boolean;
    public alergeno_huevos !: boolean;
    public alergeno_soja !: boolean;
    public alergeno_apio !: boolean;
    public alergeno_altramuz !: boolean;
    public alergeno_pescado !: boolean;
    public alergeno_sulfuro !: boolean;
    public alergeno_nueces !: boolean;
    public alergeno_mostaza !: boolean;
    public alergeno_moluscos !: boolean;
    public alergeno_sesamo !: boolean;
    public alergeno_cacahuetes !: boolean;
    public alergeno_gluten !: boolean;
    public image_path !: string;
    public updated!:Date;
    public id_tipo_plato !: number;
    public id_dificultad_plato !: number;
    public favorite !: boolean;
    public sistema !: boolean;
    public id_nutricionista !: number;

 } 

import { store } from '@/store/store';
import { cabezeras_platos } from '@/views/test/platos/cabezeras_platos';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'cabezeras_platoModule',
    store,
    dynamic: true
})
class cabezeras_platoModule extends VuexModule {
    public cabezeras_platos: cabezeras_platos[] = [];
    public cabezeras_plato: cabezeras_platos = new cabezeras_platos();

    @Action({ commit: 'onGetcabezeras_platos' })
    public async getcabezeras_platos() {
        return await ssmHttpService.get(API.cabezeras_platos);
    }

    @Action({ commit: 'onGetcabezeras_plato' })
    public async getcabezeras_plato(id: any) {
        return await ssmHttpService.get(API.cabezeras_platos + '/' + id);
    }

    @Action
    public async guardarcabezeras_plato(cabezeras_plato: cabezeras_platos) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.cabezeras_platos, cabezeras_plato.toJson());
        this.getcabezeras_platos();
    }

    @Action
    public async modificarcabezeras_plato(cabezeras_plato: cabezeras_platos) {
        await ssmHttpService.put(API.cabezeras_platos, cabezeras_plato);
        this.getcabezeras_platos();
    }

    @Action
    public async eliminarcabezeras_plato(cabezeras_plato: cabezeras_platos) {
        await ssmHttpService.delete(API.cabezeras_platos + '/' + cabezeras_plato.id, null, false);
        this.getcabezeras_platos();
    }

    @Mutation
    public onGetcabezeras_platos(res: cabezeras_platos[]) {
        this.cabezeras_platos = res ? res.map((x) => new cabezeras_platos(x)) : []
    }

    @Mutation
    public onGetcabezeras_plato(res: cabezeras_platos) {
        this.cabezeras_plato = new cabezeras_platos(res);
    }

}
export default getModule(cabezeras_platoModule);
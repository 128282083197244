import { BaseDto } from '@/shared/dtos/base-dto';

export class nueva_plato extends BaseDto {
    public id_estructura !: number;
    public vet100 !: string;
    public vet_racion !: string;
    public azucares_anadidos !: string;
    public porcentaje_gr !: string;
    public porcentaje_glucidos !: string;
    public porcentaje_protein !: string;
    public id_talla_plato !: number;

 } 

export class UtilsNumber {
    public static between(
        value: number,
        valor_1_peq: number,
        valor_2_may: number
    ): boolean {
        if (value >= valor_1_peq && value <= valor_2_may) {
            return true;
        }
        return false;
    }

    public static RoudTwoDecimals(
        num: number
    ): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }


    public static ValueToNumber(value: any): number {
        if (value === null || value === undefined) {
            return 0;
        }
        if (this.IsNullOrWhiteSpace(value)) {
            return 0;
        }
        return value;
    }

    public static IsNullOrWhiteSpace(value: string | null): boolean {
        try {
            if (value == null || value === 'undefined') {
                return true;
            }

            return value.toString().replace(/\s/g, '').length < 1;
        } catch (e) {
            return false;
        }
    }

}